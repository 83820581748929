import { PageContentContainer } from 'app/components/styles'
import { useState } from 'react'
import { ContainerTable, NavTabsList, Tab, TabPanel, TabsList } from './styles';
import { Tabs as BaseTabs } from "@mui/base/Tabs/Tabs";
import { Box, Button, CircularProgress } from '@mui/material';
import FinancialPix from './financialPix/financialPix';
import FinancialBoleto from './financialBoleto/financialBoleto';


const NewFinancial = () => {
    return (
        <Box sx={{ height: "100svh", marginTop: "-30px" }}>
            <BaseTabs defaultValue={1}>
                <TabsList>
                    <NavTabsList>
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <Tab value={1}>Pix</Tab>
                            <Tab value={2}>Boleto</Tab>
                        </Box>
                    </NavTabsList>
                </TabsList>
                <Box sx={{ minHeight: "70svh" }}>
                    <TabPanel value={1}><FinancialPix /></TabPanel>
                    <TabPanel value={2}><FinancialBoleto /></TabPanel>
                </Box>
            </BaseTabs>
        </Box>
    )
}

export default NewFinancial