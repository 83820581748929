import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { axiosInstance } from "core/api/axios/axiosInstance";
import { IPage, IResponseBody } from "core/models/utils";
import { FormHelperText } from "@mui/material";
import { debounce } from "lodash";
import { formatDateBr, formatDateBrWithHours } from "core/utils/globalFunctions";

interface IAsyncProps<T> {
  handleOnSelect: (selected: T) => void;
  urlToSearch: string;
  label: string;
  sortField: "name" | "nameRepresentative" | "createdAt" | "coupon" | "clientCpforCnpj";
  style?: React.CSSProperties | undefined;
  error?: string;
  haveError?: boolean;
  variant?: "standard" | "outlined" | "filled" | undefined;
  onClear?: () => void;
  selectedValue?: T | null;
  searchParamName: string;
}

type OptionType = { name: string; openDate: string; closingDate: string } | { nameCoupon: string };

export default function AsyncAutoComplete<T extends OptionType>({
  handleOnSelect,
  urlToSearch,
  label,
  sortField,
  style,
  error,
  haveError,
  variant,
  onClear,
  selectedValue,
  searchParamName,
}: IAsyncProps<T>) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly T[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchOptions = React.useCallback(
    debounce(async (value: string) => {
      if (value) {
        setIsLoading(true);
        try {
          const response = await axiosInstance.get<IResponseBody<IPage<T>>>(urlToSearch, {
            params: {
              page: 0,
              size: 10,
              sort: `${sortField},desc`,
              [searchParamName]: value,
            },
          });
          const filteredOptions = response.data.data.content.filter((option) => {
            if ("status" in option && option.status === "ABERTO") {
              return false;
            }
            return true;
          });
          setOptions(filteredOptions);
        } catch (error) {
          console.error("Erro ao buscar dados: ", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setOptions([]);
      }
    }, 300),
    [urlToSearch, sortField, searchParamName]
  );

  React.useEffect(() => {
    if (open) {
      fetchOptions(inputValue);
    }
  }, [open, inputValue, fetchOptions]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getOptionLabel = (option: T): string => {
    if ("name" in option) {
      return option.name;
    } else if ("nameCoupon" in option) {
      return option.nameCoupon;
    }
    return "";
  };

  return (
    <Autocomplete
      fullWidth
      value={selectedValue}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event: any, value: T | null) => {
        if (value) {
          handleOnSelect(value);
        } else if (onClear) {
          onClear();
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, value) => setInputValue(value)}
      renderOption={(props, option) => (
        <li {...props}>
          {"name" in option && "openDate" in option && "closingDate" in option
            ? `${option.name} - Fechamento: ${formatDateBrWithHours(option.closingDate)}`
            : getOptionLabel(option)}
        </li>
      )}
      isOptionEqualToValue={(option, value) => {
        if ("name" in option && "name" in value) {
          return option.name === value.name;
        } else if ("nameCompany" in option && "nameCompany" in value) {
          return option.nameCompany === value.nameCompany;
        } else if ("nameCoupon" in option && "nameCoupon" in value) {
          return option.nameCoupon === value.nameCoupon;
        }
        return false;
      }}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={isLoading}
      style={style}
      loadingText={"Procurando..."}
      renderInput={(params) => (
        <TextField
          {...params}
          size={"small"}
          fullWidth
          style={style}
          label={label}
          error={haveError}
          variant={variant}
          helperText={
            haveError && (
              <FormHelperText sx={{ margin: -0.5, padding: 0 }} error={haveError}>
                {String(error)}
              </FormHelperText>
            )
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
