import { Chart } from "react-google-charts";
import { Box, Grid } from "@mui/material";

import theme from "core/theme/theme";
import Spinner from "../spinner/spinner";
import {
  IChartProps,
  TBoletosStatusChartValues,
  TChartData,
  TChartHeader,
  TChartInfos,
  TListSalesLiquidadas,
  TPixStatusChartValues,
  TSalesStatusChartValues,
} from "../../../core/models/charts";
import { TChartSchema } from "./chartBlock";
import { getMonthNameByIndex } from "../../../core/utils/globalFunctions";

function FormatFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function formatChartData(data: any[] | any, type: TChartSchema): TChartData {
  let header: TChartHeader = [];
  let infos: TChartInfos[] = [];
  let formatedData: TChartData;

  switch (type) {
    case "listPixSalesMonth":
      header = ["Mês", "Pendente", "Pago"];
      infos = data.map((item: any) => [
        getMonthNameByIndex(item.mes - 1),
        item.pix_pendente,
        item.pix_pago,
      ]);
      break;
    case "listInvoiceSalesMonth":
      header = ["Mês", "Pendente", "Em Pagamento", "Pago"];
      infos = data.map((item: any) => [
        getMonthNameByIndex(item.mes - 1),

        item.boleto_pendente,
        item.boleto_em_pagamento,
        item.boleto_pago,
      ]);
      break;
    case "invoiceStatus":
      header = ["Status", "Total"];
      infos = data.map((item: TBoletosStatusChartValues) => [
        FormatFirstLetter(item.status),
        item.total_boletos,
      ]);
      break;
    case "salesBySellerByMonth":
      header = ["Nome", "Boletos", "Pix", "Total"];
      infos = data.listMonth.map((item: TListSalesLiquidadas) => [
        item.name,
        item.salesAmountBoleto,
        item.salesAmountPix,
        item.salesAmountBoleto + item.salesAmountPix,
      ]);
      break;
    case "pixStatus":
      header = ["Status", "Total"];
      infos = data.map((item: TPixStatusChartValues) => [
        FormatFirstLetter(item.status),
        item.total_pix,
      ]);
      break;
    case "salesStatus":
      header = ["Status", "Total"];
      infos = data.map((item: TBoletosStatusChartValues) => [
        FormatFirstLetter(item.status),
        item.total_boletos,
      ]);
      break;
    case "liquidSalesByMonth":
      header = ["Mês", "Cadastrados", "Liquidados"];
      infos = data.salesMonthData.listMonth.map((item: any, index: number) => [
        FormatFirstLetter(item.month),
        item.salesAmount,
        data.listSalesLiquiMonthData.listMonth[index].salesAmountLiqui || 0,
      ]);
      break;
    case "salesStatusMonth":
      header = ["Status", "Total"];
      infos = data.map((item: TSalesStatusChartValues) => [
        FormatFirstLetter(item.status.replace("_", " ")),
        item.total_vendas,
      ]);
      break;
  }
  return (formatedData = [header, ...infos]);
}

const DisplayCharts = (props: IChartProps) => {
  const {
    chartType,
    data,
    height,
    charTitle,
    width,
    fetchingData,
    fullWidth,
    typeReq,
  } = props;

  const options = {
    title: charTitle,
  };

  return fetchingData ? (
    <Box sx={{ height: height, width: width, display: "flex" }}>
      <Spinner state={fetchingData} css={{ margin: "auto" }} />
    </Box>
  ) : !data ? (
    <>n tem nada</>
  ) : (
    <Grid
      item
      flexGrow={1}
      sx={{
        flex: 1,
        width: fullWidth ? "100%" : "40svw",
        fontFamily: "Arial, sans-serif",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Chart
        chartType={chartType}
        data={formatChartData(data, typeReq)}
        options={chartType === "Table" ? tableChartOptions : options}
        height={height}
        width={width}
      />
    </Grid>
  );
};

export default DisplayCharts;

const tableChartOptions = {
  title: "User Chart",
  width: "100%",
  displayExactValues: true,
  showRowNumber: false,
  allowHtml: true,
  is3D: true,
  cssClassNames: {
    headerRow: "tableChartHeaderRow",
    hoverTableRow: "tableChartHeaderRowHighlightedState",
  },
};
