import { FinancialService } from "core/api/financial/financialService";
import { Order } from "core/models/table";
import { removeNonNumeric } from "core/utils/globalFunctions";

export const fetchFinancialPix = async (
  page: number,
  rowsPerPage: number,
  orderBy: string,
  order: Order,
  status?: string,
  clientcpforcnpj?: string,
  dateStart?: string,
  dateEnd?: string
) => {
  return await FinancialService.getFilteredFinancialPix({
    page: page,
    size: rowsPerPage,
    sort: orderBy + "," + order,
    status: status,
    clientcpforcnpj: clientcpforcnpj === "" ? undefined : removeNonNumeric(clientcpforcnpj),
    dateStart: dateStart,
    dateEnd: dateEnd,
  });
};


export const fetchFinancialBoleto = async (
  page: number,
  rowsPerPage: number,
  orderBy: string,
  order: Order,
  status?: string,
  clientcpforcnpj?: string,
  dateStart?: string,
  dateEnd?: string
) => {
  return await FinancialService.getFilteredFinancialBoleto({
    page: page,
    size: rowsPerPage,
    sort: orderBy + "," + order,
    status: status,
    clientcpforcnpj: clientcpforcnpj === "" ? undefined : removeNonNumeric(clientcpforcnpj),
    dateStart: dateStart,
    dateEnd: dateEnd,
  });
};