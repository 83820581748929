import { Box, Button } from "@mui/material";

import { formatCurrencyBR, formatDateBr, formatDocument, removeNonNumeric } from "core/utils/globalFunctions";
import html2pdf from "html2pdf.js";

const monthsNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

interface Props {
  dowloadFunction?: boolean;
  clientName: string;
  clientDocument: string;
  clientAddressAsString: string;
  fullValue: number;
  halfValue: number;
  createDate?: string;
}

interface DateInfos {
  day: number;
  month: string;
  year: number;
}

export const ClientClearNameDeal = ({ clientDocument, clientName, clientAddressAsString, fullValue, halfValue, dowloadFunction, createDate }: Readonly<Props>) => {

  //? documento limpo
  const cleanDocument = removeNonNumeric(clientDocument);

  // ? pega o dia, nome do mês e ano atual
  function getActualDate(): DateInfos {
    const date = new Date();
    return {
      day: date.getDate(),
      month: monthsNames[date.getMonth()],
      year: date.getFullYear(),
    };
  }

  // ? data atual
  const actualDate: DateInfos = getActualDate();

  // ? função para baixar o pdf
  const downloadPDF = () => {
    const element = document.getElementById('content');
    const opt = {
      margin: 1,
      filename: 'contrato.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div id="content">
        <p><h3 style={{ textAlign: 'center', padding: "0 10px" }}>CONTRATO PARTICULAR DE PRESTAÇÃO DE SERVIÇO COM PAGAMENTO PARCELADO </h3></p>
        <p>
          Por este instrumento particular de <b>CONTRATO DE PRESTAÇÃO DE SERVIÇOS</b>, que entre si fazem, de um lado, POSITIVO PAYMENTS SERVICOS LTDA, CNPJ nº 44.341.929/0001-50, e-mail: contato@positivobra.com.br, com endereço na Avenida Epitácio Pessoa, nº 3701, Bairro Miramar, João Pessoa – PB, CEP. 58032-000, doravante denominada CONTRATADA, e de outro lado {clientName}, sob inscrição {cleanDocument?.length === 11 ? "CPF" : "CNPJ"} nº {formatDocument(clientDocument)}, com endereço {clientAddressAsString}, doravante denominada <b>CONTRATANTE</b>.
        </p>
        <p>
          Decidem as partes, na melhor forma de direito, celebrar o presente CONTRATO DE PRESTAÇÃO DE SERVIÇOS, que reger-se-á mediante as cláusulas e condições adiante estipuladas.
        </p>
        <p><b><u>DO OBJETO</u></b></p>
        <p>
          Tem entre si contratado a prestação de serviços de intermediação de soluções administrativas para <b>INIBIÇÃO DE APONTAMENTOS NO CPF JUNTO AOS ÓRGÃOS DE PROTEÇÃO AO CRÉDITO (SPC, SERASA E BOA VISTA) E RESTAURAÇÃO DE SCORE,</b> que se regerá pelas cláusulas seguintes:
        </p>
        <p>
          <b>CLÁUSULA PRIMEIRA</b> - A Contratada prestará ao contratante, assistência desde o primeiro contato.
          <br></br>
          O presente objeto constitui responsabilidade finalística, de forma que vincula as partes aos resultados almejados.
        </p>
        <p>
          <b>
            OBRIGAÇÕES DO CONTRATANTE
          </b>
        </p>
        <p>
          <b>CLÁUSULA SEGUNDA</b> A Contratante deverá fornecer a Contratada todas as informações necessárias a realização dos serviços, devendo especificar os detalhes necessários a perfeita consecução do mesmo.
          <br></br>
          <b>2.1 </b>– A Contratante deverá efetuar os pagamentos na forma e condições descritas na cláusula quarta.
        </p>
        <p><b>OBRIGAÇÕES DO CONTRATADA</b></p>
        <p>
          <b>CLÁUSULA TERCEIRA</b> - A Contratada deverá prestar os serviços solicitados pelo Contratante conforme descrito no objeto, especificações e prazos previsto neste instrumento particular.
          <br></br>
          <b>3.1</b> – A Contratada se obriga a manter absoluto sigilo sobre os dados, operações, informações e documentos do Contratante, mesmo após a conclusão dos serviços ou do término da relação contratual.
          <br></br>
          <b>3.2</b> – Os contratos, informações, dados, materiais e documentos inerentes ao Contratante deverão ser utilizados, pela Contratada, por seus funcionários ou contratados, estritamente para cumprimento dos serviços solicitados pela Contratante, sendo VEDADO a comercialização ou utilização para outros fins.
          <br></br>
          <b>3.3</b> – Será de responsabilidade da Contratada todo o ônus trabalhista ou tributário referente aos funcionários utilizados para prestação do serviço objeto deste instrumento, ficando o Contratante isento de qualquer obrigação em relação a eles.
        </p>
        <p>
          <b>
            DO PREÇO E DAS CONDIÇÕES DE PAGAMENTO
          </b>
        </p>
        {/* preços aqui */}
        <p>
          <b>CLÁUSULA QUARTA</b> – O (A)Contratante pagará a Contratada, a título de honorários pela prestação dos serviços, conforme objeto do respectivo contrato, o valor de {formatCurrencyBR(fullValue)}, a serem pagos através de cartão de crédito bancária em conta corrente da Contratante.
        </p>
        {/* valores */}
        <p>
          ({" "}) À vista - {formatCurrencyBR(fullValue)}
          <br></br>
          ({" "}) Em 1 + 1 de {formatCurrencyBR(halfValue)} cada parcela.
        </p>
        <p>
          <b>DA VALIDADE</b>
        </p>
        <p>
          <b>CLÁUSULA QUINTA</b> - O negócio jurídico só será validado, mediante pagamento em dia e a quitação integral dos valores referentes a prestação dos serviços, mediante Boleto Bancário emitido pela CONTRATADA.
        </p>
        <p>
          <b>
            DO PRAZO DE ENTREGA DO SERVIÇO
          </b>
        </p>
        <p>
          <b>CLÁUSULA SEXTA</b> – O prazo para a entrega dos serviços de inibição de apontamentos e restauração de score, junto aos órgãos de proteção ao crédito (SERASA), será de em média, 45 (quarenta e cinco) dias úteis, a partir da assinatura do presente instrumento.
          <br></br>
          <b>6.1</b> – No caso de atraso do pagamento referente as parcelas previstas na Cláusula quarta, o prazo aqui estipulado será prorrogado por mais 45 (quarenta e cinco) dias úteis a contar do efetivo pagamento da parcela em aberto.
        </p>
        <p>
          <b>
            JUROS DE MORA
          </b>
        </p>
        <p>
          <b>CLÁUSULA SÉTIMA</b> - No caso de atraso nos pagamentos, o (a) CONTRATANTE estará automaticamente em mora, arcando com juros de 1% (um por cento) ao mês e multa de 10% (dez por cento), sem prejuízo da cobrança judicial do débito pela via executiva judicial.
        </p>
        <p>
          <b>
            DA CONFIDENCIALIDADE
          </b>
        </p>
        <p>
          <b>CLÁUSULA OITAVA</b> - Todas as Informações transmitidas pela CONTRATANTE para a CONTRATADA serão consideradas Informações Confidenciais, entendidas como todos os documentos, materiais, pormenores, informações, especificações técnicas, dados, cadastros, cálculos, preços, estudos, projeções e outras informações técnicas e/ou comerciais, inovações e aperfeiçoamento ou acesso que venha a lhe ser confiado, sob qualquer forma de registro, fornecidas pela Parte CONTRATANTE ou por outrem em nome dela, relacionadas aos serviços deste Contrato ou relacionadas às Partes, tanto as exclusivas como aquelas obtidas, preparadas ou desenvolvidas no curso deste Contrato, com exceção das previstas na lei, sob pena de aplicação de multa contratual no importe de 30% (trinta por cento) sobre o valor do contrato.
        </p>
        <p>
          <b>
            DO PRAZO DE VALIDADE
          </b>
        </p>
        <p>
          <b>CLÁUSULA NONA</b> - Este Contrato entrará em vigor a partir da data da assinatura pelas partes, e terá validade de 6 (SEIS) meses, para a segurança e baixa das restrições dos nomes dos contratantes, contados a partir da assinatura do presente instrumento.
        </p>
        <p>
          <b>
            DO DESCUMPRIMENTO E RESCISÃO CONTRATUAL
          </b>
        </p>
        <p>
          <b>CLÁUSULA DÉCIMA</b> - O descumprimento de qualquer uma das cláusulas por qualquer uma das partes, implicará na rescisão imediata deste contrato, não isentando a Contratada de suas responsabilidades referentes ao zelo com informações e dados do Contratante.
          <br></br>
          <b>10.1</b> - Caso o Contratante atrase o pagamento pelo período de 15 (quinze) dias, será rescindido imediatamente o presente instrumento, e o valor do contrato será executado imediatamente, acrescidos de honorários advocatícios em 20% (por cento).
          <br></br>
          <b>10.2</b> - Qualquer tolerância das partes quanto ao descumprimento das cláusulas do
          presente contrato constituirá mera liberalidade, não configurando renúncia ou novação do contrato ou de suas cláusulas que poderão ser exigidos a qualquer tempo.
        </p>
        <p>
          <b>
            DA PREVENÇÃO À LAVAGEM DE DINHEIRO E AO FINANCIAMENTO DO TERRORISMO
          </b>
        </p>
        <p>
          <b>CLÁUSULA DÉCIMA PRIMEIRA</b> – A CONTRATADA e seus representantes declaram ter conhecimento das leis que dispõem sobre a prevenção e combate aos crimes de lavagem de dinheiro e de financiamento do terrorismo e das normas complementares editadas pelo Banco Central do Brasil (BCB), Conselho Monetário Nacional (CMN) e Secretaria da Receita Federal (SRF) e, ainda, tem ciência que a CONTRATANTE, por força dessas leis e normas, está obrigada a comunicar às autoridades competentes a ocorrência de operações previstas nas referidas normas.
          <br></br>
          <b>11.1</b> A CONTRATADA e seus representantes declaram ter conhecimento que a CONTRATANTE não pactua com comportamentos ou atos ilícitos e que possui política organizacional (ANEXO I), com diretrizes preventivas, que prezam pela prevenção à lavagem de dinheiro e ao financiamento do terrorismo, nos termos da Circular nº 3.978/2020 do BCB.
          <br></br>
          <b>11.2</b> A CONTRATADA declara, por si e por seus administradores (“Representantes”), empregados, colaboradores, contratados, sócios, acionistas, empresas integrantes do seu grupo econômico, que:
          (i) Atua em conformidade com todas as leis, regulamentações e normas relacionadas ao combate e prevenção à corrupção, à lavagem de dinheiro e ao.
          <br></br>
          <b>11.3</b> A CONTRATADA se compromete a informar à CONTRATANTE caso algum de seus financiamento do terrorismo.
          <br></br>
          (ii) Não realizou, não realiza e não realizará quaisquer atos ou práticas que, direta ou indiretamente, envolvam oferecimento, promessas, suborno, extorsão, autorização, solicitação, aceite, pagamento, entrega ou qualquer outro ato relacionado a vantagem pecuniária indevida ou qualquer outro favorecimento ilegal em desconformidade com a legislação mencionada acima.
          <br></br>
          <b>11.4</b> Representantes seja pessoa politicamente exposta, conforme definido pela Circular nº 3.978/2020 do BCB ou norma vigente sobre o tema.
          <br></br>
          <b>11.5</b> O não cumprimento das disposições previstas nesta Cláusula pela CONTRATADA poderá acarretar a rescisão unilateral deste Contrato, pela CONTRATANTE, que poderá automaticamente suspender a execução deste Contrato e/ou resili-lo imediatamente.
          <br></br>
          <b>11.6</b> A violação desta Cláusula, pela CONTRATADA ou por seus Representantes, ensejará, ainda, a obrigação de indenizar a CONTRATANTE por eventuais perdas e danos, nos termos deste Contrato.
          <br></br>
          <b>11.7</b> A CONTRATADA se compromete a comunicar imediatamente a CONTRATANTE no caso de ocorrência de qualquer violação, suspeita de violação ou qualquer situação irregular que se apresente contra a legislação aplicável acerca de combate e prevenção à lavagem de dinheiro, ao financiamento do terrorismo e à corrupção, bem como acordos e convenções internacionais que regulamente.
        </p>
        <p>
          <b>
            DA SEGURANÇA CIBERNÉTICA
          </b>
        </p>
        <p>
          <b>CLÁUSULA DÉCIMA SEGUNDA</b> - A CONTRATADA e seus representantes declaram ciência de que o a CONTRATANTE possui Política de Segurança Cibernética que prevê a adoção de medidas que busquem assegurar a confidencialidade, a integridade e a disponibilidade dos dados e dos sistemas de informação utilizados no âmbito deste Contrato, nos termos da Resolução CMN nº 4.893/2021, devendo ser dado conhecimento aos seus colaboradores, contratados, parceiros e prestadores de serviço.
        </p>
        <p>
          <b>12.1</b> A CONTRATADA e seus representantes declaram, ainda, ciência acerca do conteúdo da Política Institucional de Segurança Cibernética da CONTRATANTE, quanto aos objetivos e responsabilidades e compromete-se no exercício das atividades, prestação de serviços ou fornecimento de bens ou produtos objeto deste Contrato, adotar procedimentos e controles compatíveis aos utilizados pelas entidades.
        </p>
        <p>
          <b>12.2</b> Em caso da prestação de serviços e/ou fornecimento de bens e produtos objeto deste Contrato envolver serviços de processamento e armazenamento de dados e de computação em nuvem, manuseio de dados ou informações sensíveis e que sejam relevantes para a condução das atividades da CONTRATANTE, as partes concordam em executar procedimentos e controles que visem prevenir, detectar e reduzir vulnerabilidades e impactos relacionados aos incidentes cibernéticos, nos termos previsto na Política Institucional de Segurança Cibernética.
        </p>
        <p>Dentre os procedimentos que deverão ser adotados pelas Partes, estão:</p>
        <p>
          a) Autenticação e criptografia.
        </p>
        <p>
          b) prevenção e a detecção de intrusão.
        </p>
        <p>
          c) prevenção de vazamento de informações.
        </p>
        <p>
          d) realização periódica de testes e varreduras para detecção de vulnerabilidades.
        </p>
        <p>
          e) proteção contra softwares maliciosos.
        </p>
        <p>
          f) estabelecimento de mecanismos de rastreabilidade e controles de acesso e de segmentação da rede de computadores.
        </p>
        <p>
          g) manutenção de cópias de segurança dos dados e das informações.
        </p>
        <p>
          h) a identificação daqueles que têm acesso às informações da CONTRATANTE deve ser única, pessoal e intransferível, qualificando-o como responsável pelas ações realizadas.
        </p>
        <p>
          i) A concessão de acesso às informações e recursos de informação somente imprescindíveis para o pleno desempenho das atividades objeto deste Contrato.
        </p>
        <p>
          Ainda, caso o objeto do contrato envolva acesso ao ambiente e rede tecnológica da CONTRATANTE, o acesso deverá se restringir a necessidade para execução dos serviços, bem como deverá atender aos requisitos de segurança contidos na Política Institucional de Segurança da Informação e na Política Institucional de Segurança Cibernética.
        </p>
        <p>
          a) termos firmados deste contrato;
        </p>
        <p>
          b) documentação e informações referentes aos serviços prestados; e
        </p>
        <p>
          c) dependências da CONTRATADA.
        </p>
        <p>
          <b>12.3</b> O não cumprimento das disposições previstas nesta Cláusula pela CONTRATADA poderá acarretar a rescisão unilateral deste Contrato pela CONTRATANTE, que poderá automaticamente suspender a execução deste Contrato e/ou resili-lo imediatamente.
        </p>
        <p>
          <b>12.4</b> A violação desta Cláusula, pela CONTRATADA ou por seus Representantes, ensejará, ainda, a obrigação de indenizar a CONTRATANTE por eventuais perdas e danos, nos termos deste Contrato.
        </p>
        <p>
          <b>12.5</b> A CONTRATADA se compromete a comunicar imediatamente a CONTRATANTE no caso de ocorrência de quaisquer incidentes de segurança cibernética, violação, suspeita de violação ou qualquer situação irregular que se apresente contra a legislação aplicável acerca da Segurança Cibernética.
        </p>
        <p>
          <b>
            DO COMPROMISSO ANTICORRUPÇÃO DO PACTO DE ÉTICA E DA EXPLORAÇÃO DE MÃO DE OBRA EM CONDIÇÕES ANÁLOGAS A DE TRABALHO ESCRAVO
          </b>
        </p>
        <p>
          <b>CLÁUSULA DÉCIMA TERCEIRA</b> - As PARTES assumem o compromisso de não praticar qualquer ato que atente:
        </p>
        <p>
          a) contra o patrimônio público nacional e/ou estrangeiro;
        </p>
        <p>
          b) contra princípios da administração pública ou;
        </p>
        <p>
          c) contra os compromissos internacionais assumidos pelo Brasil, conforme disposto na Lei nº 12.846/2013, que dispõe sobre a responsabilização administrativa e civil de pessoas jurídicas pela prática de atos contra a administração pública, nacional e/ou estrangeira.
        </p>
        <p>As partes declaram ciência e concordância com o Pacto de Ética do CONTRATANTE, em especial as seguintes premissas:</p>
        <p>
          a) observância de critérios técnicos, profissionais, éticos, não ensejando favorecimento de qualquer natureza;
        </p>
        <p>
          b) idoneidade, imparcialidade, transparência e ética; e
        </p>
        <p>
          c) cumprimento das exigências legais, em especial nos aspectos tributários, trabalhistas e previdenciários.
        </p>
        <p>
          <b>13.1</b>Sem prejuízo do disposto acima, não será admitida a exploração de mão de obra em condições análogas à de trabalho escravo e/ou trabalho infantil em qualquer nível da cadeia de suprimentos.
        </p>
        <p>
          <b>13.2</b> O descumprimento dos itens dispostos acima implicará na imediata rescisão do presente contrato, independentemente de notificação, sem prejuízo de eventual indenização cabível, à PARTE prejudicada, bem como por eventuais perdas ou danos.
        </p>
        <p>
          <b>
            DA RESPONSABILIDADE SOCIOAMBIENTAL
          </b>
        </p>
        <p>
          <b>CLÁUSULA DÉCIMA QUARTA</b> - Em referência às ações socioambientais da CONTRATANTE que são resultado do empenho na prevenção e no gerenciamento de riscos e impactos socioambientais, a CONTRATADA se compromete a proteger e preservar o meio ambiente, bem como evitar quaisquer práticas que possam lhe causar danos, executando seus serviços em estrita observância das normais legais e regulamentares, federais, estaduais ou municipais que disciplinam, incentivam e promovem o engajamento sustentável.
        </p>
        <p>
          <b>
            DO FORO
          </b>
        </p>
        <p>
          <b>CLÁUSULA DÉCIMA QUINTA</b> - Os casos omissos ou dúvidas que surgirem serão dirimidas na forma da legislação aplicável, ficando eleito o foro da Comarca de João Pessoa - Paraíba, para qualquer ação fundada neste contrato, renunciando-se a qualquer outro, por muito especial que seja.
        </p>
        <p>
          E por estarem em perfeito acordo em tudo quanto neste instrumento particular foi lavrado, obrigando-se as partes a cumprir o presente contrato.
        </p>
        <p>
          João Pessoa – PB, {createDate ? formatDateBr(createDate) : `${actualDate.day} de ${actualDate.month} de 2024`}.
        </p>
      </div>
      {dowloadFunction && <Button sx={{ margin: 'auto' }} onClick={downloadPDF}>Download PDF</Button>}
    </div>
  );
}