import { Box, Button, Typography } from "@mui/material";
import DefaultModal from "../../defaultModal/defaultModal";
import AsyncAutoComplete from "app/components/assyncAutoComplete/asyncAutoComplete";
import { SELLERS } from "core/utils/constants";
import { useState } from "react";
import { SaleService } from "core/api/sale/saleService";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    sale: any
    reRender: any;
}

const AlterSellerModal = (props: IProps) => {
    const { isOpen, onClose, onOpen, sale, reRender } = props;
    const [seller, setSeller] = useState<any>();
    const [searchSeller, setSearchSeller] = useState<any>();

    const handleSelectSeller = async (selected: any) => {
        setSeller(selected);
    };

    const onClearSeller = () => {
        setSeller(undefined);
        setSearchSeller(undefined);
    };

    const handleConfirmSeller = async () => {
        await SaleService.alterSeller(sale.id, seller.id);
        reRender();
        setSeller(undefined);
        setSearchSeller(undefined);
        onClose();
    };

    return (
        <DefaultModal
            title="Trocar vendedor"
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            children={
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: "0.8rem", minWidth: 360, alignItems: "center" }}>
                    <AsyncAutoComplete
                        label="Pesquisar vendedor"
                        searchParamName="name"
                        selectedValue={searchSeller}
                        handleOnSelect={handleSelectSeller}
                        sortField="name"
                        urlToSearch={SELLERS}
                        onClear={onClearSeller}
                    />
                    {seller && <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                        <Typography sx={{ fontWeight: "bold" }}>Vendedor selecionado:  </Typography>
                        <Typography>{seller.name} - {seller.cpforcnpj}</Typography>
                    </Box>}
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 3, justifyContent: "center", width: '100%' }}>
                        <Button onClick={() => {
                            setSeller(undefined);
                            setSearchSeller(undefined);
                            onClose();
                        }}>
                            CANCELAR
                        </Button>
                        <Button disabled={!seller} onClick={() => {
                            handleConfirmSeller();
                        }}>
                            CONFIRMAR
                        </Button>
                    </Box>
                </Box>
            }
        />
    )
}

export default AlterSellerModal