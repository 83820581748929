import {SaleService} from "core/api/sale/saleService";
import {TSaleStatusType} from "core/models/sale";
import {Order} from "core/models/table";
import { removeNonNumeric } from "core/utils/globalFunctions";

export const fetchSales = async (
    page: number,
    rowsPerPage: number,
    orderBy: string,
    order: Order,
    status?: TSaleStatusType,
    cameThrough?: string,
    typePayment?: string,
    sellerCpfOrCnpj?: string,
    clientCpforCnpj?: string,
    indicationCpforCnpj?: string,
    createDate?: string,
    sellerId?: number,
    typeSales?: string,
    dateEnd?: string,
    dateStart?: string
) => {
    return await SaleService.getFilteredSales({
        page: page,
        size: rowsPerPage,
        sort: orderBy + "," + order,
        status,
        cameThrough,
        typePayment,
        sellerCpfOrCnpj,
        clientCpforCnpj: clientCpforCnpj === "" ?  undefined : removeNonNumeric(clientCpforCnpj),
        indicationCpforCnpj,
        createDate,
        sellerId,
        typeSales,
        dateEnd,
        dateStart,
    });
};
