import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

import { TItemList } from "core/models/lists";
import theme from "core/theme/theme";
import { SalesCell } from "../sales/styles";
import { formatDateBr, formatDateBrWithHours, formatDocument } from "core/utils/globalFunctions";
import { StyledDivDataTable } from "app/components/table/tableHead/styles";
import React, { useEffect, useMemo, useState } from "react";
import DefaultDialog from "app/components/defaultDialog/defaultDialog";
import { ListsService } from "../../../core/api/lists";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import Search from "../../components/search/Search";
import SendIcon from '@mui/icons-material/Send';
import { TSaleFilterRequest } from "../../../core/models/sale";
import DefaultModal from "app/components/modals/defaultModal/defaultModal";
import { useQuery } from "@tanstack/react-query";
import { Order } from "core/models/table";
import { debounce } from "lodash";
import AsyncAutoComplete from "app/components/assyncAutoComplete/asyncAutoComplete";
import { GET_LISTS } from "core/utils/constants";

interface IDisplayListProps {
    list?: TItemList[];
    refresh: any;
    listStatus: string;
    refeshList: any;
}

type TList = {
    id: number;
    name: string;
    openDate: string;
    closingDate: string;
    updatedDate: string;
    status: string;
}

export const DisplayList = ({ list, refresh, listStatus, refeshList }: Readonly<IDisplayListProps>) => {

    const [confirmRemove, setConfirmRemove] = useState(false);
    const [moveItemModal, setMoveItemModal] = useState(false);
    const [idToRemove, setIdToRemove] = useState<number>();
    const [tempList, setTempList] = useState<TList>();
    const [tempItemList, setTempItemList] = useState<TItemList>();
    const [isLoading, setIsLoading] = useState(false);


    // ? remover cliente da lista
    const handleRemoveClient = async () => {
        await ListsService.removeItemList(idToRemove!);
        refresh();
        setIdToRemove(undefined);
        setConfirmRemove(false);
    };

    const handleSelectList = async (selected: TList) => {
        setTempList(selected);
    };

    const onClearList = () => {
        setTempList(undefined);
    };

    useEffect(() => {
        console.log(tempList);

    }, [tempList]);

    const rmvItem = (id: number) => {
        setIdToRemove(id);
        setConfirmRemove(true);
    };

    const handleCloseModal = () => {
        setMoveItemModal(false);
        setTempList(undefined);
        setTempItemList(undefined);
    };

    const handleConfirmMoveItem = async () => {
        if (tempItemList && tempList) {
            setIsLoading(true);
            try {
                await ListsService.moveItemList(String(tempList.id), String(tempItemList.id));
                setMoveItemModal(false);
                setTempList(undefined);
                setTempItemList(undefined);
                refresh();
                window.location.reload();
            } catch (error) {
                console.error("Erro ao mover item: ", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <Container>
            <DefaultDialog
                title="Remover da lista"
                isOpen={confirmRemove}
                onCloseAction={() => setConfirmRemove(false)}
                confirmAction={handleRemoveClient}
            />
            <TableContainer sx={{ maxHeight: 300 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledDivDataTable>Nome</StyledDivDataTable>
                            <StyledDivDataTable>Cpf</StyledDivDataTable>
                            <StyledDivDataTable>Pagamento</StyledDivDataTable>
                            <StyledDivDataTable>Mover</StyledDivDataTable>
                            <StyledDivDataTable>Remover</StyledDivDataTable>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((row: TItemList) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                    backgroundColor: theme.COLORS.WHITE,
                                }}
                            >
                                <SalesCell component="th" scope="row" size="small">
                                    <Infos>{row?.nameClient}</Infos>
                                </SalesCell>
                                <SalesCell component="th" scope="row" size="small">
                                    <Infos>{formatDocument(row?.cpforCnpj)}</Infos>
                                </SalesCell>
                                <SalesCell component="th" scope="row" size="small">
                                    <Infos>{formatDateBr(row?.createdAt)}</Infos>
                                </SalesCell>
                                <SalesCell>
                                    <Infos>
                                        <IconButton disabled={listStatus !== "ABERTO"} onClick={() => { setMoveItemModal(true); setTempItemList(row) }}>
                                            <SendIcon style={{ color: listStatus !== "ABERTO" ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3 }} />
                                        </IconButton>
                                    </Infos>
                                </SalesCell>
                                <SalesCell align="center" component="th" scope="row" size="small">
                                    <Infos>
                                        <IconButton disabled={listStatus !== "ABERTO"} onClick={() => rmvItem(row?.id!)}>
                                            <CloseIcon style={{ color: listStatus !== "ABERTO" ? theme.COLORS.GRAY4 : theme.COLORS.RED }} />
                                        </IconButton>
                                    </Infos>
                                </SalesCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <DefaultModal
                title="Selecione uma lista"
                isOpen={moveItemModal}
                onOpen={() => setMoveItemModal(true)}
                onClose={handleCloseModal}
                children={
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: "0.8rem", minWidth: 380, alignItems: "center" }}>
                        <AsyncAutoComplete
                            label="Pesquise uma lista pelo mês"
                            searchParamName="name"
                            selectedValue={tempList}
                            handleOnSelect={handleSelectList}
                            sortField="name"
                            urlToSearch={GET_LISTS}
                            onClear={onClearList}
                        />
                        {tempList &&
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold" }}>Lista selecionada:  </Typography>
                                <Typography>{tempList.name} - Fechamento: {formatDateBrWithHours(tempList.closingDate)} </Typography>
                            </Box>
                        }

                        <Box sx={{ display: "flex", flexDirection: "row", gap: 3, justifyContent: "center", width: '100%' }}>
                            <Button onClick={() => {
                                setTempList(undefined);
                                setTempItemList(undefined);
                                setMoveItemModal(false);
                            }} disabled={isLoading}>
                                CANCELAR
                            </Button>
                            <Button onClick={() => {
                                handleConfirmMoveItem();
                            }} disabled={isLoading || !tempItemList || !tempList}>
                                CONFIRMAR
                            </Button>
                        </Box>
                    </Box>

                }
            />
        </Container>
    );
};

const Container = styled.div`
    background-color: ${theme.COLORS.GRAY7};
    border-radius: 5px;
    display: flex;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #f3f3f3;
    flex-direction: column;
`;

const Infos = styled(Typography)`
    font-size: 0.9pc !important;
`;
