/* eslint-disable no-lone-blocks */
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import React, { useState } from "react";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import PixInfo from "./pix";
import BoletoInfo from "./boleto";
import { TBoletoBodyResponse } from "core/models/payment/boleto";
import { TPixBodyResponse } from "core/models/payment/pix";
import { PaymentSection, PaymentListContainer, PaymentList } from "./styles";
import {
  formatCEP,
  formatDocument,
  formatMoney,
  formatPhoneNumber,
  validarCpfCnpj,
} from "core/utils/globalFunctions";
import {
  InfosSection,
  Container,
  Title,
  InfoCardContainer,
  InfoCardTitle,
  InfoCard,
  InfoRow,
  InfoKey,
  InfoValue,
} from "app/components/styles";
import theme from "core/theme/theme";
import DefaultModal from "../../defaultModal/defaultModal";
import { ClientClearNameDeal } from "app/views/nameCleaner/microComponents/contract/deal";
import { useQuery } from "@tanstack/react-query";
import { fetchClientDeal } from "core/querryes/sale/getCientDealQuerry";
import { TPayment } from "core/models/sale";
import { SaleService } from "core/api/sale/saleService";
import DefaultDialog from "app/components/defaultDialog/defaultDialog";
import { set } from "date-fns";
import AsyncAutoComplete from "app/components/assyncAutoComplete/asyncAutoComplete";
import { COUPON } from "core/utils/constants";
import { TCouponResponse } from "core/models/coupons";
import { idText } from "typescript";
import { CouponService } from "core/api/coupons/couponService";
import { useAppSelector } from "core/hooks/reduxHooks";
import HailIcon from "@mui/icons-material/Hail";
import AlterSellerModal from "../alterSeller/alterSellerModal";

interface IProps {
  sales: any | undefined;
  reRender: () => void;
}

const SalesInfo = (sale: IProps) => {
  const { reRender, sales } = sale;
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const [viewContract, setViewContract] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [confirmChange, setConfirmChange] = useState(false);
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [idCouponState, setIdCoupon] = useState<number | undefined>();
  const [valuePixCpf, setValuePixCpf] = useState<number | undefined>();
  const [valuePixCnpj, setValuePixCnpj] = useState<number | undefined>();
  const [valueInstallmentCpf, setValueInstallmentCpf] = useState<number | undefined>();
  const [valueInstallmentCnpj, setValueInstallmentCnpjn] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [alterSellerModal, setAlterSellerModal] = useState(false);

  const dealQuerry = useQuery({
    queryKey: ["deal", sales?.client?.id],
    queryFn: () => fetchClientDeal(sales?.client?.id),
  });

  const handleSelectCoupon = (selected: TCouponResponse) => {
    console.log("cupom Selecionado: ", selected.id);
    setIdCoupon(Number(selected.id));
    setValuePixCpf(Number(selected.valuePixCpf))
    setValuePixCnpj(Number(selected.valuePixCnpj))
    setValueInstallmentCpf(Number(selected.valueInstallmentCpf))
    setValueInstallmentCnpjn(Number(selected.valueInstallmentCnpj))
  };

  const DisplayPayments = (): React.ReactNode => {
    return sales?.paymentMethods.map(
      (payment: TBoletoBodyResponse | TPixBodyResponse) => {
        if (payment.type === "PIX") {
          const pix = payment as TPixBodyResponse;
          return (
            <PixInfo
              idSale={sales.id}
              saleStatus={sales.status}
              reRender={reRender}
              pix={pix}
              idPayment={pix.id}
              key={pix.id}
            />
          );
        } else if (payment.type === "BOLETO") {
          const boleto = payment as TBoletoBodyResponse;
          return (
            <BoletoInfo
              saleStatus={sales.status}
              boleto={boleto}
              client={sales.client}
              reRender={reRender}
              key={boleto.installment}
            />
          );
        }
        return null;
      }
    );
  };

  function salePrice(
    saleType: string,
    documentType: string,
    paymentType: string,
    coupon?: { pixValue?: number; installmentValue?: number }
  ): number {
    let price: number;
    if (saleType === "CONSULTORIA") {
      price = 297;
    } else {
      const prices: any = {
        PIX: {
          CPF: 1500,
          CNPJ: 2000,
        },
        BOLETO: {
          CPF: 800,
          CNPJ: 1050,
        },
      };
      price = prices[paymentType]?.[documentType] || 0;
    }
    if (coupon?.installmentValue && paymentType === "BOLETO") {
      price = coupon?.installmentValue;
    }
    if (coupon?.pixValue && paymentType === "PIX") {
      price = coupon?.pixValue;
    }
    return price;
  }

  // ? mudar o tipo da venda com base no tipo atual
  const changeSaleType = async (id: string, type: TPayment) => {
    setProcessingRequest(true);

    if (type === 'PIX' || type === 'BOLETO') {
      // console.log("Mudando tipo de pagamento");
      // console.log('Método atual', sales.typePayment);
      // console.log('Novo método', type === 'PIX' ? 'BOLETO' : 'PIX');
      await SaleService.changeSalePaymentType({ id, typePayment: type === 'PIX' ? 'BOLETO' : 'PIX' });
      reRender();
      setProcessingRequest(false);
      setConfirmChange(false);
    }

    return setProcessingRequest(false);
  }

  const handleConfirmCoupon = async (idSale: number) => {
    setIsLoading(true)
    if (idCouponState) {
      await CouponService.addCoupon(idSale, idCouponState);
      reRender();
      setAddCouponModal(false)
      handleClearIdCoupon()
    }
    setIsLoading(false)
  };

  const handleClearIdCoupon = () => {
    setIdCoupon(undefined);
    setValueInstallmentCnpjn(undefined)
    setValueInstallmentCpf(undefined)
    setValuePixCnpj(undefined)
    setValuePixCpf(undefined)
  };

  // @ts-ignore
  return (
    <>
      <DefaultDialog
        title="Confirmação"
        isOpen={confirmChange}
        confirmAction={() => changeSaleType(sales.id, sales.typePayment)}
        onCloseAction={() => setConfirmChange(false)}
        body={
          <Box sx={{ width: 200 }}>
            <Typography textAlign={'start'}>Deseja realmente mudar a forma de pagamento desta
              compra?</Typography>
            <Typography textAlign={'center'}>Método atual: <span
              style={{ fontWeight: "bold" }}>{sales.typePayment}</span></Typography>
          </Box>
        }
      />
      <Container>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={1}
        >
          <Title>Detalhes da venda</Title>
          <Tooltip title="Mudar método de pagamento" >
            <IconButton disabled={processingRequest || sales.status === "EM_PAGAMENTO" || sales.status === "PAGO" || sales.typeSales === "CONSULTORIA"} onClick={() => setConfirmChange(true)}>
              <SwapHorizIcon sx={{ color: sales.status === "EM_PAGAMENTO" || sales.status === "PAGO" || sales.typeSales === "CONSULTORIA" ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Contrato desta venda">
            <IconButton onClick={() => setViewContract(true)}>
              <ReceiptLongIcon sx={{ color: theme.COLORS.BLUE3 }} />
            </IconButton>
          </Tooltip>
          {userInfo?.group === "ADMIN" &&
            <>
              <Tooltip title='Adicionar cupom a venda'>
                <IconButton onClick={() => setAddCouponModal(true)} disabled={sales.status === "EM_PAGAMENTO" || sales.status === "PAGO" || sales.typeSales === "CONSULTORIA"}>
                  <BookmarkAddIcon sx={{ color: sales.status === "EM_PAGAMENTO" || sales.status === "PAGO" || sales.typeSales === "CONSULTORIA" ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Trocar vendedor'>
                <IconButton onClick={() => setAlterSellerModal(true)}>
                  < HailIcon sx={{ color: theme.COLORS.BLUE3 }} />
                </IconButton>
              </Tooltip>
            </>
          }


        </Box>
        <InfosSection>
          <Box
            sx={{
              gap: "1rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <InfoCardContainer>
              <InfoCardTitle>Cliente</InfoCardTitle>
              <InfoCard sx={{ width: 500 }}>
                <InfoRow>
                  <InfoKey>Nome:</InfoKey>
                  <InfoValue>{sale.sales?.client.name}</InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoKey>cpf/cnpj:</InfoKey>
                  <InfoValue>
                    {formatDocument(sale.sales?.client.cpforcnpj!)}
                  </InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoKey>Email:</InfoKey>
                  <InfoValue>{sale.sales?.client.email}</InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoKey>Contato:</InfoKey>
                  <InfoValue>
                    {formatPhoneNumber(sale.sales?.client.telephone!)}
                  </InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoKey>Veio por:</InfoKey>
                  <InfoValue>{sale.sales?.cameThrough ?? "--"}</InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoKey>Cep:</InfoKey>
                  <InfoValue>{formatCEP(sale.sales?.client.cep!)}</InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoKey>Bairro:</InfoKey>
                  <InfoValue>{sale.sales?.client.neighborhood}</InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoKey>Estado:</InfoKey>
                  <InfoValue>{sale.sales?.client.uf}</InfoValue>
                </InfoRow>
              </InfoCard>
            </InfoCardContainer>
            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <InfoCardContainer>
                <InfoCardTitle>Vendedor</InfoCardTitle>
                <InfoCard sx={{ width: 250 }}>
                  <InfoRow>
                    <InfoKey>Nome:</InfoKey>
                    <InfoValue>{sale.sales?.seller.name}</InfoValue>
                  </InfoRow>
                  <InfoRow>
                    <InfoKey>cpf/cnpj:</InfoKey>
                    <InfoValue>
                      {formatDocument(sale.sales?.seller.cpforcnpj!)}
                    </InfoValue>
                  </InfoRow>
                  <InfoRow>
                    <InfoKey>Email:</InfoKey>
                    <InfoValue>{sale.sales?.seller.email}</InfoValue>
                  </InfoRow>
                </InfoCard>
              </InfoCardContainer>
              {sale.sales?.indication && (
                <InfoCardContainer>
                  <InfoCardTitle>Indicação</InfoCardTitle>
                  <InfoCard sx={{ width: 250 }}>
                    <InfoRow>
                      <InfoKey>Nome:</InfoKey>
                      <InfoValue>{sale.sales?.indication.coupon}</InfoValue>
                    </InfoRow>
                    <InfoRow>
                      <InfoKey>cpf/cnpj:</InfoKey>
                      <InfoValue>
                        {formatDocument(sale.sales?.indication.login!)}
                      </InfoValue>
                    </InfoRow>
                  </InfoCard>
                </InfoCardContainer>
              )}
            </Box>
          </Box>
          {sale.sales?.coupon && (
            <InfoCardContainer>
              <InfoCardTitle>Cupom</InfoCardTitle>
              <InfoCard>
                <InfoRow>
                  <InfoKey>Nome:</InfoKey>
                  <InfoValue>{sale.sales?.coupon?.coupon}</InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoKey>Descrição:</InfoKey>
                  <InfoValue>{sale.sales?.coupon?.description}</InfoValue>
                </InfoRow>

                {sale.sales?.typePayment === "PIX" ? (
                  (sale.sales?.client.cpforcnpj).length === 11 ? (
                    <InfoRow>
                      <InfoKey>Valor pix CPF:</InfoKey>
                      <InfoValue>
                        {formatMoney(sale.sales?.coupon?.valuePixCpf)}
                      </InfoValue>
                    </InfoRow>
                  ) : (
                    <InfoRow>
                      <InfoKey>Valor pix CNPJ:</InfoKey>
                      <InfoValue>
                        {formatMoney(sale.sales?.coupon?.valuePixCnpj)}
                      </InfoValue>
                    </InfoRow>
                  )
                ) : (
                  <React.Fragment>
                    <InfoRow>
                      <InfoKey>Quantidades de parcelas:</InfoKey>
                      <InfoValue>
                        {sale.sales?.coupon?.quantityInstallments}
                      </InfoValue>
                    </InfoRow>
                    {(sale.sales?.client.cpforcnpj).length === 11 ? (
                      <InfoRow>
                        <InfoKey>Valor em parcelas CPF:</InfoKey>
                        <InfoValue>
                          {formatMoney(sale.sales?.coupon?.valueInstallmentCpf)}
                        </InfoValue>
                      </InfoRow>
                    ) : (
                      <InfoRow>
                        <InfoKey>Valor em parcelas CNPJ:</InfoKey>
                        <InfoValue>
                          {formatMoney(
                            sale.sales?.coupon?.valueInstallmentCnpj
                          )}
                        </InfoValue>
                      </InfoRow>
                    )}
                  </React.Fragment>
                )}
              </InfoCard>
            </InfoCardContainer>
          )}
        </InfosSection>
        <PaymentSection>
          <InfoCardTitle sx={{ marginLeft: "1rem" }}>Pagamentos</InfoCardTitle>
          <PaymentListContainer>
            <PaymentList>{DisplayPayments()}</PaymentList>
          </PaymentListContainer>
        </PaymentSection>
      </Container>
      <DefaultModal
        title="Contrato da venda"
        isOpen={viewContract}
        onClose={() => setViewContract(false)}
        onOpen={() => setViewContract(true)}
      >
        <Box
          sx={{
            padding: 2,
            width: 700,
            height: 400,
            overflowY: "scroll",
            background: theme.COLORS.GRAY7,
          }}
        >
          {!dealQuerry.isLoading && (
            <ClientClearNameDeal
              clientAddressAsString={`${sales?.client.address}, ${sales?.client.neighborhood}, ${sales?.client.city}, ${sales?.client.uf}, ${sales?.client.cep}`}
              clientDocument={sales?.client.cpforcnpj!}
              clientName={sales?.client.name!}
              fullValue={salePrice(
                dealQuerry.data[0].etypeSales,
                dealQuerry.data[0].typeClient,
                "PIX",
                dealQuerry.data[0].couponDTO
              )}
              halfValue={salePrice(
                dealQuerry.data[0].etypeSales,
                dealQuerry.data[0].typeClient,
                "BOLETO",
                dealQuerry.data[0].couponDTO
              )}
              dowloadFunction={true}
              createDate={dealQuerry.data[0].dateSale}
            />
          )}
        </Box>
      </DefaultModal>
      <DefaultModal
        title="Adicionar cupom a venda"
        isOpen={addCouponModal}
        onClose={() => {
          setAddCouponModal(false);
          handleClearIdCoupon();
        }}
        onOpen={() => setAddCouponModal(true)}
        children={
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 1,
            gap: 2
          }}>
            <AsyncAutoComplete
              style={{ width: 200 }}
              label="Procurar um cupom"
              urlToSearch={COUPON}
              sortField="createdAt"
              handleOnSelect={handleSelectCoupon}
              onClear={handleClearIdCoupon}
              searchParamName="nameCoupon"
            />
            {<Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography>Valor em pix Cpf: {valuePixCpf ? valuePixCpf : ""}</Typography>
                <Typography>Valor em pix Cnpj: {valuePixCnpj ? valuePixCnpj : ""}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography>Valor em boleto
                  Cpf: {valueInstallmentCpf ? valueInstallmentCpf : ""}</Typography>
                <Typography>Valor em boleto
                  Cnpj: {valueInstallmentCnpj ? valueInstallmentCnpj : ""}</Typography>
              </Box>
            </Box>}
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Button onClick={() => {
                handleClearIdCoupon();
                setAddCouponModal(false);
              }}>
                CANCELAR
              </Button>
              <Button disabled={!idCouponState || isLoading}
                onClick={() => handleConfirmCoupon(Number(sales.id))}>
                CONFIRMAR
              </Button>
            </Box>
          </Box>
        }
      />
      <AlterSellerModal
        sale={sales}
        isOpen={alterSellerModal}
        onClose={() => setAlterSellerModal(false)}
        onOpen={() => setAlterSellerModal(true)}
        reRender={reRender}
      />
    </>
  );
};

export default SalesInfo;
