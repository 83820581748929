import { PageContentContainer } from 'app/components/styles'
import { ButtonDownload, Container, ContractBox } from './styles'
import { Box, Button, Icon } from '@mui/material'
import { useAppSelector } from 'core/hooks/reduxHooks'
import DownloadIcon from '@mui/icons-material/Download';
import { useQuery } from '@tanstack/react-query'
import { fetchClientDeal } from 'core/querryes/client/clientDealQuerry'
import { useNavigate } from 'react-router-dom'
import { TRole, verifyRole } from 'core/utils/roles'
import { ClientClearNameDeal } from '../nameCleaner/microComponents/contract/deal'
import { formatCEP, removeNonNumeric } from 'core/utils/globalFunctions';

const Contract = () => {
  const navigate = useNavigate();
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
  const clientInfos = useAppSelector((state) => state.auth.client);
  const acceptRoles: TRole[] = [
    "ROLE_COURSES",];
  const notAcceptGroup: string = "ROLE_ADMIN";

  const dealInfo = useQuery({
    queryKey: ["installments"],
    queryFn: () => fetchClientDeal(),
  });

  function salePrice(saleType: string, documentType: string, paymentType: string, coupon?: { pixValue?: number, installmentValue?: number }): number {
    let price: number;
    if (saleType === "CONSULTORIA") {
      price = 297;
    } else {
      const prices: any = {
        PIX: {
          CPF: 1500,
          CNPJ: 2000,
        },
        BOLETO: {
          CPF: 800,
          CNPJ: 1050,
        },
      };
      price = prices[paymentType]?.[documentType] || 0;
    }
    if (coupon?.installmentValue && paymentType === "BOLETO") {
      price = coupon?.installmentValue;
    }
    if (coupon?.pixValue && paymentType === "PIX") {
      price = coupon?.pixValue;
    }
    return price;
  }

  // const handleClick = () => {
  //   const content =
  //     handleDeal(
  //       clientInfos?.name || " ",
  //       clientInfos?.cpforcnpj || " ",
  //       clientInfos?.address || " ",
  //       dealInfo.data
  //     ) || "";

  //   const htmlString = ReactDOMServer.renderToString(<div>{content}</div>);

  //   const htmlContent = `
  //           <html>
  //             <head>
  //               <title>Contrato</title>
  //             </head>
  //             <body>
  //               <div style="max-width: 800px; margin: 0 auto;">
  //                 ${htmlString}
  //               </div>
  //             </body>
  //           </html>
  //         `;
  //   const newWindow = window.open();
  //   if (newWindow) {
  //     newWindow.document.write(htmlContent);
  //     newWindow.document.close();
  //   }
  // };

  if (
    !verifyRole(basicUserInfo?.roles, acceptRoles) ||
    basicUserInfo?.group === notAcceptGroup
  ) {
    navigate(-1);
    return null;
  }

  return (

    <PageContentContainer>
      <Container>
        <Box sx={{ display: "flex", justifyContent: "center", overflowY: "auto", height: "80svh" }}>
          <ContractBox>
            {!dealInfo.isLoading && <ClientClearNameDeal
              createDate={dealInfo.data[0].dateSale}
              dowloadFunction={true}
              clientName={clientInfos?.name!}
              clientDocument={removeNonNumeric(clientInfos?.cpforcnpj)!}
              fullValue={salePrice(dealInfo.data[0].etypeSales, dealInfo.data[0].typeClient, 'PIX', dealInfo.data[0].couponDTO)}
              halfValue={salePrice(dealInfo.data[0].etypeSales, dealInfo.data[0].typeClient, 'BOLETO', dealInfo.data[0].couponDTO)}
              clientAddressAsString={`${clientInfos?.address}, ${clientInfos?.neighborhood}, ${clientInfos?.city}, ${clientInfos?.uf}, ${formatCEP(clientInfos?.cep!)}`}
            />}
          </ContractBox>
        </Box>
      </Container>
    </PageContentContainer>

  )
}

export default Contract