import {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Divider, IconButton, Tooltip} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import {useAppSelector} from "core/hooks/reduxHooks";
import {ClientService} from "core/api/client/clientService";
import {
    ContentHeader,
    PageContentContainer,
} from "app/components/styles";

import theme from "core/theme/theme";
import ChartBlock, {IGraphData} from "../../../components/charts/chartBlock";
import {
    CHART_BOLETOS_STATUS, CHART_LIST_SALES_LIQUIDADAS,
    CHART_PIX_STATUS,
    CHART_SALES_STATUS,
    LIST_SALES_MONTH
} from "../../../../core/utils/constants";

const Dashboard = () => {
    const navigate = useNavigate();
    const basicUserInfo = useAppSelector((state) => state.auth.userInfo);

    const chartBlockRef = useRef<any>(null);

    const handleRefreshCharts = () => {
        if (chartBlockRef.current) {
            chartBlockRef.current.refetchCharts();
        }
    };

    // ! navegar para a página correta
    useEffect(() => {
        if (basicUserInfo) {
            console.log(`User Group: ${basicUserInfo.group}`);
            if (basicUserInfo.group === "CLIENT") {
                console.log('Navigating to /aulas');
                ClientService.getDeal();
                navigate("aulas");
            } else if (basicUserInfo.group === "INDICATION") {
                console.log('Navigating to /vendas');
                navigate("/vendas");
            } else if (basicUserInfo.group === "SELLER") {
                console.log('Navigating to /links');
                navigate("/links");
            }
        } else {
            console.error('basicUserInfo is null or undefined');
        }
    }, [basicUserInfo, navigate]);

    const chartTest: IGraphData[] = [
        {
            title: "Vendas PIX",
            url: LIST_SALES_MONTH,
            dataSchema: "listPixSalesMonth",
            chartType: "ColumnChart"
        },
        {
            title: "Vendas BOLETO",
            url: LIST_SALES_MONTH,
            dataSchema: "listInvoiceSalesMonth",
            chartType: "ColumnChart"
        },
    ]

    const chartToMount: IGraphData[] = [
        {
            title: "Status vendas por Boletos",
            url: CHART_BOLETOS_STATUS,
            dataSchema: "invoiceStatus",
            chartType: "PieChart"
        },
        {
            title: "Status vendas por Pix",
            url: CHART_PIX_STATUS,
            dataSchema: "pixStatus",
            chartType: "PieChart"
        },
        {
            title: "Status geral das vendas",
            url: CHART_SALES_STATUS,
            dataSchema: "salesStatusMonth",
            chartType: "PieChart"
        },
        {
            title: "Vendas dos vendedores",
            url: CHART_LIST_SALES_LIQUIDADAS,
            dataSchema: "salesBySellerByMonth",
            chartType: "Table"
        },
    ]

    return (
        <PageContentContainer sx={{gap: "1em", backgroundColor: theme.COLORS.WHITE, margin: "1em", borderRadius: "5px"}}>
            <ContentHeader>
                <Tooltip title="Atualizar gráficos" onClick={handleRefreshCharts}>
                    <IconButton>
                        <CachedIcon sx={{color: theme.COLORS.BLUE3}}/>
                    </IconButton>
                </Tooltip>
            </ContentHeader>
            <ChartBlock ref={chartBlockRef} charts={chartTest} periodType={"year"} />
            <Divider />
            <ChartBlock ref={chartBlockRef} charts={chartToMount} />
        </PageContentContainer>
    );
};

export default Dashboard;
