import {useEffect, useState} from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useQuery} from "@tanstack/react-query";
import {
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
} from "@mui/material";

import {PageContentContainer} from "app/components/styles";
import {StyledDivDataTable} from "app/components/table/tableHead/styles";
import {ListsService} from "core/api/lists";
import {Order} from "core/models/table";
import {SalesCell, SalesTypography} from "../sales/styles";
import theme from "core/theme/theme";
import DataTablePagination from "app/components/table/pagination/pagination";
import {formatDateBr, formatDateBrWithHours} from "core/utils/globalFunctions";
import ManageListModal from "./ManageListModal";
import {TList} from "../../../core/models/lists";

const Lists = () => {

    const [tempList, setTempList] = useState<TList>();
    const [modal, setModal] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("name");

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, list: TList) => {
        setTempList(list);
        setModal(true);
    };

    // ? requisição de listas
    const lists = useQuery({
        queryKey: [orderBy, page, rowsPerPage, order],
        queryFn: async () => await ListsService.getLists({
            page: page,
            size: rowsPerPage,
            sort: orderBy + "," + order,
        }),
        staleTime: Infinity,
    });

    useEffect(() => {
        if (lists.isSuccess && lists.data) {
            setCount(lists.data.totalElements);
        }
    }, [lists.isSuccess, lists.data]);

    return (
        <PageContentContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledDivDataTable>Mês</StyledDivDataTable>
                        <StyledDivDataTable align="left">
                            Data de abertura
                        </StyledDivDataTable>
                        <StyledDivDataTable align="left">
                            Data de fechamento
                        </StyledDivDataTable>
                        {/*<StyledDivDataTable align="left">*/}
                        {/*    Total de clientes*/}
                        {/*</StyledDivDataTable>*/}
                        <StyledDivDataTable>Status</StyledDivDataTable>
                        <StyledDivDataTable align="center">Detalhes</StyledDivDataTable>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lists.data?.content.map((row: TList) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                "&:last-child td, &:last-child th": {border: 0},
                                backgroundColor: theme.COLORS.WHITE,
                            }}
                        >
                            <SalesCell component="th" scope="row" size="small">
                                <SalesTypography>{row?.name}</SalesTypography>
                            </SalesCell>
                            <SalesCell component="th" scope="row" size="small">
                                <SalesTypography>{formatDateBrWithHours(row?.openDate)}</SalesTypography>
                            </SalesCell>
                            <SalesCell component="th" scope="row" size="small">
                                <SalesTypography>{formatDateBrWithHours(row?.closingDate ?? "")}</SalesTypography>
                            </SalesCell>
                            <SalesCell component="th" scope="row" size="small">
                                <SalesTypography>{row?.status}</SalesTypography>
                            </SalesCell>
                            <SalesCell align="right" component="th" scope="row" size="small">
                                <SalesTypography>
                                    <IconButton onClick={(e) => {
                                        handleClick(e, row);
                                    }}>
                                        <VisibilityIcon style={{color: theme.COLORS.BLACK}}/>
                                    </IconButton>
                                </SalesTypography>
                            </SalesCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {tempList && <ManageListModal
                list={tempList}
                isOpen={modal}
                onClose={() => setModal(false)}
                onOpen={() => setModal(true)}
                refresh={lists.refetch}
            />}

            <DataTablePagination
                setPage={setPage}
                page={page}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                count={count}
            />
        </PageContentContainer>
    );
};

export default Lists;