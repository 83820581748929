import { FINANCIAL_PIX, FINANCIAL_BOLETO } from "core/utils/constants";
import { axiosInstance } from "../axios/axiosInstance";
import { IPage, IResponseBody } from "core/models/utils";
import { TFinancialBoletoPageable, TFinancialPixPageable } from "core/models/financial";

const getFilteredFinancialPix = async (
  financialPageable: TFinancialPixPageable
): Promise<IPage<any> | undefined> => {
  const response = await axiosInstance.get<IResponseBody<IPage<any>>>(
    FINANCIAL_PIX,
    {
      params: {
        page: financialPageable.page,
        size: financialPageable.size,
        sort: "createdAt,desc",
        status: financialPageable?.status,
        clientcpforcnpj: financialPageable?.clientcpforcnpj,
        dateStart: financialPageable?.dateStart,
        dateEnd: financialPageable?.dateEnd,
      },
    }
  );
  return response.data.data;
};

const getFilteredFinancialBoleto = async (
  financialPageable: TFinancialBoletoPageable
): Promise<IPage<any> | undefined> => {
  const response = await axiosInstance.get<IResponseBody<IPage<any>>>(
    FINANCIAL_BOLETO,
    {
      params: {
        page: financialPageable.page,
        size: financialPageable.size,
        sort: "createdAt,desc",
        status: financialPageable?.status,
        clientcpforcnpj: financialPageable?.clientcpforcnpj,
        dateStart: financialPageable?.dateStart,
        dateEnd: financialPageable?.dateEnd,
      },
    }
  );
  return response.data.data;
};

export const FinancialService = {
  getFilteredFinancialPix,
  getFilteredFinancialBoleto,
};
