import { Box } from "@mui/material";
import styled from "styled-components";


export const ContainerVideos = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  border-radius: 10px; /* Adicione o raio da borda aqui */
  border: 3px solid ${({ theme }) => theme.COLORS.BLUE2} !important; /* Usando a cor do tema */
  color: ${({ theme }) => theme.COLORS.BLUE3} !important; /* Cor do texto do tema, se necessário */
  `;

