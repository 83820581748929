import { Box, IconButton, Table, TableBody, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query';
import DefaultFilter, { ISelectItem } from 'app/components/filter/defaultFilter';
import { PageContentContainer } from 'app/components/styles'
import DataTablePagination from 'app/components/table/pagination/pagination';
import DataTable from 'app/components/table/table/table';
import TableHeader from 'app/components/table/tableHeader/TableHeader';
import { ContentBody } from 'app/views/sellerList/styles';
import useFinancialFilterHook from 'core/hooks/filters/financialHook';
import { useAppSelector } from 'core/hooks/reduxHooks';
import { TFinancialPixFilterRequest } from 'core/models/financial';
import { ITableHeadCell, Order } from 'core/models/table';
import { fetchFinancialPix } from 'core/querryes/financial/financialQuerry';
import { TRole, verifyRole } from 'core/utils/roles';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MoreHorizRounded from "@mui/icons-material/MoreHorizRounded";
import Search from 'app/components/search/Search';
import { StyledDivDataTable } from 'app/components/table/tableHead/styles';
import Spinner from 'app/components/spinner/spinner';
import Sales from 'app/views/sales/sales';
import { SalesCell, SalesTypography } from 'app/views/sales/styles';
import { formatCurrency, formatCurrencyBR, formatDateBr, formatDocument } from 'core/utils/globalFunctions';
import theme from 'core/theme/theme';
import StyledStatus from 'app/components/styledStatus/styledStatus';


export const head: ITableHeadCell[] = [
    { name: "sellerName", label: "Nome do vendedor", align: "left" },
    { name: "sellerCpforcnpj", label: "Vendedor", align: "left" },
    { name: "clientCpforcnpj", label: "Cliente", align: "left" },
    { name: "value", label: "Valor", align: "left" },
    { name: "txid", label: "txid", align: "left" },
    { name: "duedate", label: " Data vencimento", align: "left" },
    { name: "datecreation", label: "Data criação", align: "left" },
    { name: "status", label: "Status", align: "left" },
];

export const items: ISelectItem[] = [
    { name: "CPF / CNPJ", value: "clientcpforcnpj", type: "texto" },
    { name: "Status", value: "status", type: "status2" },
    { name: "Data de pagamento", value: "daterange", type: "daterange" },

];

const FinancialPix = () => {
    const navigate = useNavigate();
    const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
    const { isOpen, onClose, onOpen } = useFinancialFilterHook();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("id");
    const [filters, setFilters] = useState<TFinancialPixFilterRequest>({
        status: undefined,
        clientcpforcnpj: "",
        dateStart: undefined,
        dateEnd: undefined,
    });
    const acceptRoles: TRole[] = [
        "ROLE_ADMIN",];
    const notAcceptGroup: string = "CLIENT";


    const { data, isSuccess, isLoading, refetch } = useQuery({
        queryKey: ["financialPix", page, rowsPerPage, orderBy, order, filters],
        staleTime: Infinity,
        queryFn: () =>
            fetchFinancialPix(page, rowsPerPage, orderBy, order, filters.status, filters.clientcpforcnpj,
                 filters.dateStart, filters.dateEnd),
    });

    useEffect(() => {
        if (isSuccess && data) {
            setCount(data.totalElements);
        }
    }, [isSuccess, data]);

    const removeFilter = (attribute: string) => {
        setFilters((prevState) => ({
            ...prevState,
            [attribute]: undefined,
        }));
    };


    if (
        !verifyRole(basicUserInfo?.roles, acceptRoles) ||
        basicUserInfo?.group === notAcceptGroup
    ) {
        navigate(-1);
        return null;
    }

    return (
        <PageContentContainer>
            <TableHeader
                filter={filters}
                filterBtn={true}
                filterBtnAction={() => onOpen()}
                remove={removeFilter}
                extraComponents={
                    <Search
                        searchPlaceHolder="Cpf/cnpj do cliente..."
                        cpfClient={filters.clientcpforcnpj}
                        querrySearching={isLoading}
                        onChange={(e: string) => setFilters((prevState) => ({
                            ...prevState,
                            clientcpforcnpj: e,
                        }))}
                    />
                }
            />
            <ContentBody>
                {isLoading ? (
                    <Box sx={{ position: "relative", height: 500 }}>
                        <Spinner
                            state={isLoading}
                            size={10}
                            css={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                            }}
                        />
                    </Box>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledDivDataTable>Cliente</StyledDivDataTable>
                                <StyledDivDataTable>Vendedor</StyledDivDataTable>
                                <StyledDivDataTable>Valor</StyledDivDataTable>
                                <StyledDivDataTable>Txid</StyledDivDataTable>
                                <StyledDivDataTable>Datas</StyledDivDataTable>
                                <StyledDivDataTable>Status</StyledDivDataTable>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.content.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <SalesCell
                                        component="th"
                                        scope="row"
                                        size="small"
                                    >
                                        <SalesTypography fontWeight={"bold"}>
                                            {row.clientName}
                                        </SalesTypography>
                                        <SalesTypography>
                                            {formatDocument(row.clientCpforcnpj)}
                                        </SalesTypography>
                                    </SalesCell>
                                    <SalesCell
                                        component="th"
                                        scope="row"
                                        size="small"
                                    >
                                        <SalesTypography fontWeight={"bold"}>
                                            {row.sellerName}
                                        </SalesTypography>
                                        <SalesTypography>
                                            {formatDocument(row.sellerCpforcnpj)}
                                        </SalesTypography>
                                    </SalesCell>
                                    <SalesCell
                                        component="th"
                                        scope="row"
                                        size="small"
                                    >
                                        <SalesTypography>
                                            {formatCurrencyBR(row.value)}
                                        </SalesTypography>
                                    </SalesCell>
                                    <SalesCell
                                        component="th"
                                        scope="row"
                                        size="small"
                                        width={220}
                                    >
                                        <SalesTypography>
                                            {row.txid}
                                        </SalesTypography>
                                    </SalesCell>
                                    <SalesCell
                                        component="th"
                                        scope="row"
                                        size="small"
                                        width={220}
                                    >
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginRight: "25px" }}>
                                            <SalesTypography>
                                                Criação:
                                            </SalesTypography>
                                            <SalesTypography fontWeight={"bold"}>{formatDateBr(row.datecreation)}</SalesTypography>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginRight: "25px" }}>
                                            <SalesTypography>
                                                Vencimento:
                                            </SalesTypography>
                                            <SalesTypography fontWeight={"bold"}>{formatDateBr(row.duedate)}</SalesTypography>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginRight: "25px" }}>
                                            <SalesTypography>
                                                Pagamento:
                                            </SalesTypography>
                                            <SalesTypography fontWeight={"bold"}>{formatDateBr(row.paymentDate)}</SalesTypography>
                                        </Box>
                                    </SalesCell>
                                    <SalesCell
                                        component="th"
                                        scope="row"
                                        size="small"
                                    >
                                        <SalesTypography>
                                            <StyledStatus status={row.status} />
                                        </SalesTypography>
                                    </SalesCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>)}

                <DataTablePagination
                    setPage={setPage}
                    page={page}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                />
            </ContentBody>
            <DefaultFilter
                isOpen={isOpen}
                items={items}
                onChangeFilter={setFilters}
                onClose={onClose}
                onOpen={onOpen}
                title="Filtrar transações"
            />
        </PageContentContainer>
    );
}

export default FinancialPix