// chartBlock.tsx


import MountChart from "./mountChart";
import {Box, Grid} from "@mui/material";
import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {GoogleChartWrapperChartType} from "react-google-charts";
import DateRangeDisplay from "../dateRange/dateRangeDisplay/dateRangeDisplay";
import theme from "../../../core/theme/theme";

export type TChartSchema =
    "salesStatus"
    | "salesStatusMonth"
    | "salesBySellerByMonth"
    | "pixStatus"
    | "invoiceStatus"
    | "pix&InvoiceSalesByMonth"
    | "liquidSalesByMonth"
    | "pix&InvoiceByLiquidSalesMonth"
    | "listSalesMonth"
    | "listPixSalesMonth"
    | "listInvoiceSalesMonth";

// ? dados para gerar um gráfico
export interface IGraphData {
    title: string;
    url: string;
    periodType?: "mes" | "ano";
    dataSchema: TChartSchema;
    chartType: GoogleChartWrapperChartType;
}

interface ICharBlockProps {
    charts: IGraphData[];
    periodType?: "month" | "year";
    onRefresh?: () => void;
}

const CharBlock = forwardRef(({charts, periodType, onRefresh}: ICharBlockProps, ref) => {
    const chartRefs = useRef<any[]>([]);

    useImperativeHandle(ref, () => ({
        refetchCharts: () => {
            chartRefs.current.forEach(chartRef => {
                if (chartRef) chartRef.refetch();
            });
        },
    }));

    const [initDate, setInitDate] = useState<Dayjs | null | undefined>(dayjs().startOf(periodType ?? "month"));
    const [finalDate, setFinalDate] = useState<Dayjs | null | undefined>(dayjs().endOf(periodType ?? "month"));

    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <DateRangeDisplay
                typeOfDatePicker={periodType === "month" ? "month" : "year"}
                initialDate={initDate}
                endDate={finalDate}
                setInitialDate={setInitDate}
                setFinalDate={setFinalDate}
            />
            <Grid container direction="row">
                {charts.map((chart, index) => (
                    <MountChart
                        ref={el => (chartRefs.current[index] = el)}
                        initDate={initDate}
                        finalDate={finalDate}
                        chartType={chart.chartType}
                        dataSchema={chart.dataSchema}
                        url={chart.url}
                        title={chart.title}
                        periodType={chart.periodType}
                    />
                ))}
            </Grid>
        </Box>
    );
});

export default CharBlock;