import {forwardRef, Ref, useEffect, useImperativeHandle} from "react";
import dayjs, {Dayjs} from "dayjs";
import {useQuery} from "@tanstack/react-query";

import {IGraphData} from "./chartBlock";
import {ChartsService} from "../../../core/api/charts/chartsService";
import {formatDateToChartReq, periodoAtual} from "../../../core/utils/globalFunctions";
import DisplayCharts from "./displayCharts";

interface IMountChartProps extends IGraphData {
    initDate: Dayjs | null | undefined;
    finalDate: Dayjs | null | undefined;
}

// ? este componente deve fazer as reqs e controlar o date range

const MountChart = forwardRef(({
                                   url,
                                   title,
                                   periodType,
                                   dataSchema,
                                   chartType,
                                   initDate,
                                   finalDate,
                               }: IMountChartProps, ref) => {

    // ! dados
    const chartData = useQuery({
        queryKey: ["chartData", url, initDate, finalDate],
        staleTime: Infinity,
        queryFn: async () => await ChartsService.getChartDataByUrl(url, {
            dataInicial: formatDateToChartReq(initDate!),
            dataFinal: formatDateToChartReq(finalDate!),
        }),
    })

    useImperativeHandle(ref, () => ({
        refetch: chartData.refetch,
    }));

    return (
        <DisplayCharts
            chartType={chartType ?? "ColumnChart"}
            data={chartData.data}
            charTitle={title}
            width={"100%"}
            height={"300px"}
            typeReq={dataSchema}
            fetchingData={chartData.isLoading}
            dataRange={periodoAtual(periodType ?? "mes")}
        />
    );
});

export default MountChart;