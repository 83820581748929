
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab } from '@mui/base/Tab';
import { tabClasses } from '@mui/base/Tab';

export const ContainerTable = styled(Box)`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.COLORS.WHITE} !important;
    margin: 10px;
    height: 90svh;
    overflow: hidden;
`;

export const TitleHeader = styled(Typography)`
    background: ${({ theme }) => theme.COLORS.BLUE3} !important;
    color: ${({ theme }) => theme.COLORS.WHITE} !important;
    font-weight: bold !important;
    padding: 1rem;
    text-align: center;
`;

export const NavTabsList = styled(Box)`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 10px;
`;


export const BoxButton = styled(Box)`
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
    padding: 1rem;
    flex: 1;
`;


export const Tab = styled(BaseTab)`
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.COLORS.BLUE3};
    background-color: transparent;
    cursor: pointer;
    border-radius: 1px;
    min-width: 100px;
    border: none;
    padding-bottom: 0;

    &.${tabClasses.selected} {
        outline: none;
        border-bottom: 3px solid ${({ theme }) => theme.COLORS.GRAY4}; /* Adicionando uma borda na parte inferior */
    }

    &.${buttonClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;


export const TabPanel = styled(BaseTabPanel)`
    display: flex;
    align-items: center;
`;

export const TabsList = styled(BaseTabsList)`
    background-color: transparent;
    `;